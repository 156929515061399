(function () {
  const getScroll = () => window.scrollY || document.documentElement.scrollTop;
  const getSectionsHeight = (name) => {};
  let prevScroll = getScroll();
  let curScroll;
  let direction = 0;
  let prevDirection = 0;
  const header = document.getElementById("header");

  const handleScroll = () => {
    curScroll = getScroll();
    if (curScroll > prevScroll) {
      direction = 2;
    } else if (curScroll < prevScroll) {
      direction = 1;
    }

    if (direction !== prevDirection) {
      toggleHeader(direction, curScroll);
    }

    prevScroll = curScroll;
  };

  const toggleHeader = (direction, curScroll) => {
    const height = header.offsetHeight;
    if (direction === 2 && curScroll > height) {
      header.classList.add("hide");
      prevDirection = direction;
    } else if (direction === 1) {
      header.classList.remove("hide");
      prevDirection = direction;
    }
  };

  window.addEventListener("scroll", handleScroll);

  const navObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.target.id) {
          document
            .querySelector(`#nav-${entry.target.id}`)
            .classList.add("active");
        } else if (entry.target.id) {
          document
            .querySelector(`#nav-${entry.target.id}`)
            .classList.remove("active");
        }
      });
    },
    { rootMargin: "-49% 0px -49% 0px" }
  );
  document.querySelectorAll("section").forEach((el) => {
    navObserver.observe(el);
  });

  const fadeUpObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("faded");
          fadeUpObserver.unobserve(entry.target);
        }
      });
    },
    { threshold: 0 }
  );

  document.querySelectorAll(".fade-up").forEach((el) => {
    fadeUpObserver.observe(el);
  });

  const loadCurrentProgress = async () => {
    const resp = await fetch(
      "https://r1q68ap3z9.execute-api.eu-north-1.amazonaws.com/progress/tacmed"
    );
    const resp_1 = await resp.json();
    return Number.parseInt(resp_1.value);
  };
  const target = 20800000;
  const initial = 6859900;
  const addedDonation1 = 3086406;
  const setProgress = (num) => {
    const percentage = (num / target) * 100;
    document.getElementById("amount").innerText = num
      .toLocaleString("en")
      .replaceAll(",", " ");
    document.getElementById("progress-bar").style = `width: ${percentage}%`;
  };
  loadCurrentProgress()
    .then((progress) => setProgress(initial + addedDonation1 + progress))
    .catch(() => {});
})();

(function () {
  const mySiema = new Siema({
    draggable: true,
    onChange: () => {
      updateIndicators();
    },
  });
  function updateIndicators() {
    const indicators = document.querySelector(".carousel-indicators").children;
    for (let index = 0; index < indicators.length; index++) {
      if (index === mySiema.currentSlide) {
        indicators[index].classList.add("active");
      } else {
        indicators[index].classList.remove("active");
      }
    }
  }
  updateIndicators();
  document
    .querySelector(".carousel__button--prev")
    .addEventListener("click", () => mySiema.prev());
  document
    .querySelector(".carousel__button--next")
    .addEventListener("click", () => mySiema.next());
  const indicators = document.querySelector(".carousel-indicators").children;
  for (let index = 0; index < indicators.length; index++) {
    indicators[index].addEventListener("click", () => {
      mySiema.goTo(index);
    });
  }
})();
